<template>
  <component :is="tag" class="hb-raw-btn" v-bind="btnBinding">
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { BtnMixin, useBtn } from 'wue'
import { computed } from 'vue'

export default {
  name: 'HbRawBtn',
  mixins: [BtnMixin],
  props: {
    type: { type: String, default: () => 'button' },
  },
  setup(props, ctx) {
    const computedProps = computed(() => ({
      ...props,
      blockClass: 'hb-raw-btn--block',
      loadingClass: 'hb-raw-btn--loading',
      disabledClass: 'hb-raw-btn--disabled',
    }))
    const btnCtx = useBtn(computedProps, ctx)

    return {
      ...btnCtx,
    }
  },
}
</script>

<style lang="scss">
.hb-raw-btn {
  position: relative;
  cursor: pointer;
  line-height: 0;
  text-decoration: none;

  &,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
    background: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
  }

  &--loading {
    @include btn-loading(true, 0.2);
  }
}
</style>
